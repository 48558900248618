<template>
  <div class="card accordion">
    <div class="accordion-item my-0">
      <div :id="`accordionHeading_${accordionId}`" class="card-header">
        <div :class="{ 'accordion-open': !isClosed }">
          <h5 @click="isClosed = !isClosed" class="my-0 " type="button" data-bs-toggle="collapse" :data-bs-target="`#${accordionId}`" aria-expanded="true" aria-controls="collapseOne">
            <span class="justify-content-between align-items-center d-flex gap-6">{{ title }}

              <span class="functions d-inline-flex gap-3">
                <Button @click="emit('edit')" v-if="withEdit" html-type="action" icon="material-symbols-light:edit-outline">
                  Bearbeiten

                </Button>
                <Button @click="emit('delete')" v-if="withDelete" html-type="action" icon="material-symbols-light:delete-forever-outline">
                  Löschen
                </Button>
                <span class="round-icon round-icon-accordion" :class="{ show: isClosed }"><Icon icon="material-symbols-light:keyboard-arrow-up" /></span>
              </span>
            </span>
          </h5>
        </div>
      </div>
      <div :id="accordionId" class="accordion-collapse collapse" :class="[isClosed ? '' : 'show']" :aria-labelledby="`accordionHeading_${accordionId}`" data-bs-parent="#accordionExample">
        <div class="accordion-body card-body" :class="padding">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  title: {
    type: String,
  },
  icon: {
    type: String,
  },
  withEdit: {
    type: Boolean,
    default: false,
  },
  withDelete: {
    type: Boolean,
    default: false,
  },
  padding: {
    type: String,
    default: "py-3 px-4",
  },
  accordionId: {
    type: String,
    default: "test",
  },
});
const emit = defineEmits([ "edit", "delete" ]);
const isClosed = useCookie(props.accordionId);
</script>
