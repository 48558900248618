<template>
  <NuxtLayout>
    <Breadcrumb :steps="[{ name: 'Fehler', icon: 'material-symbols-light:close-small-outline' }]" />
    <div>
      <div class="row mb-4">
        <div class="col-12">
          <Card>
            <div class="d-flex">
              <div>
                <img class="errorImage" src="~/assets/img/main/404.png" alt="Tixu Fehler">
              </div>
              <div class="ps-4 w-100">
                <Headline class="mb-0">Fehler {{ parseInt(errorData.statusCode) }}</Headline>
                <Headline class="d-inline-block mb-2 text-2xl text-primary">Ooops...</Headline>
                <div v-if="parseInt(errorData.statusCode) === 404">
                  <div class="subheading">
                    Die von Ihnen gewählte Adresse ist nicht vorhanden.
                  </div>
                  <p>
                    Möglicherweise haben Sie einen veralteten Link bzw. ein altes Bookmark verwendet.
                    Versuchen Sie den von Ihnen gewünschten Inhalt über unsere Startseite zu finden.
                  </p>
                  <NuxtLink to="/" class="btn btn-icon btn-secondary mt-3">
                    <div class="icon d-inline-flex bg-white align-items-center justify-content-center" style="width:24px;height:24px;">
                      <Icon icon="material-symbols-light:keyboard-double-arrow-left" />
                    </div>
                    <div class="text">
                      <div class="text-firstLine">Zurück zur Startseite</div>
                      <div class="text-secondLine">Zurück zur Startseite</div>
                    </div>
                  </NuxtLink>
                </div>
                <div v-else class="w-100">
                  <div class="subheading">
                    Es ist ein Fehler aufgetreten.
                  </div>
                  <p>Bitte versuchen Sie es später erneut.</p>
                  <NuxtLink to="/" class="btn btn-icon btn-secondary mt-3">
                    <div class="icon d-inline-flex bg-white align-items-center justify-content-center" style="width:24px;height:24px;">
                      <Icon icon="material-symbols-light:keyboard-double-arrow-left" />
                    </div>
                    <div class="text">
                      <div class="text-firstLine">Zurück zur Startseite</div>
                      <div class="text-secondLine">Zurück zur Startseite</div>
                    </div>
                  </NuxtLink>
                  <AccordionCard v-if="errorData.stack" class="mt-4" :title="errorData.message">
                    <div>{{ errorData.stack }}</div>
                  </AccordionCard>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  </NuxtLayout>
</template>

<script setup>
import Breadcrumb from "~/components/global/Breadcrump.vue";

const errorData = useError();
const show = ref(false);

const { runtimeConfig } = useNuxtApp();

useSeoMeta({
  title: `Fehler ${errorData?.value.statusCode} ${runtimeConfig.public.titleSeparator} ${runtimeConfig.public.siteName}`,
  ogImage: "/images/tixu.jpg",
  description: runtimeConfig.public.siteDescription,
});
</script>
