import { default as _91id_934ZWuaOswF8Meta } from "/app/pages/admin-groups/[id].vue?macro=true";
import { default as indexnqshLyEGcZMeta } from "/app/pages/admin-groups/index.vue?macro=true";
import { default as admin_45passwordySuk729nHNMeta } from "/app/pages/admin-password.vue?macro=true";
import { default as _91id_93beSdQH3MBSMeta } from "/app/pages/admins/[id].vue?macro=true";
import { default as indexbfLlA1cqPPMeta } from "/app/pages/admins/index.vue?macro=true";
import { default as _91id_93c7tReH6grJMeta } from "/app/pages/article-groups/[id].vue?macro=true";
import { default as indexP1CnPDCqk1Meta } from "/app/pages/article-groups/index.vue?macro=true";
import { default as _91id_93GfbQespuG8Meta } from "/app/pages/articles/[id].vue?macro=true";
import { default as indexnFjuerN8cOMeta } from "/app/pages/articles/index.vue?macro=true";
import { default as _91id_93Z5L4MqZBatMeta } from "/app/pages/clients/[id].vue?macro=true";
import { default as indexwYSyEtRV2HMeta } from "/app/pages/clients/index.vue?macro=true";
import { default as code_45checkPO2a98MchtMeta } from "/app/pages/code-check.vue?macro=true";
import { default as _91id_93zJv1nbROrlMeta } from "/app/pages/custom-dashboards/[id].vue?macro=true";
import { default as index_deprecatedSd5Oco6tVVMeta } from "/app/pages/custom-dashboards/index_deprecated.vue?macro=true";
import { default as index8eQYOfyVjPMeta } from "/app/pages/custom-dashboards/index.vue?macro=true";
import { default as index0BjuCgbIktMeta } from "/app/pages/dashboards/index.vue?macro=true";
import { default as _91id_93JEAC5F6UZ2Meta } from "/app/pages/email-template/[id].vue?macro=true";
import { default as index5tHTuHPE2tMeta } from "/app/pages/email-template/index.vue?macro=true";
import { default as index3QkSrmWestMeta } from "/app/pages/event-dates-orders/index.vue?macro=true";
import { default as _91id_9322uPchKg2tMeta } from "/app/pages/event-dates/[id].vue?macro=true";
import { default as edit9gCEm6hzdNMeta } from "/app/pages/event-dates/edit.vue?macro=true";
import { default as index3i2ckkM9YUMeta } from "/app/pages/event-dates/index.vue?macro=true";
import { default as _91id_932G5SX2LjFTMeta } from "/app/pages/events/[id].vue?macro=true";
import { default as indexWgIHVzns1AMeta } from "/app/pages/events/index.vue?macro=true";
import { default as export_45datevMVL7plpCStMeta } from "/app/pages/export/export-datev.vue?macro=true";
import { default as _91id_93IksUdDnzziMeta } from "/app/pages/filters/[id].vue?macro=true";
import { default as index3WCdzcyqjgMeta } from "/app/pages/filters/index.vue?macro=true";
import { default as import_45users1oJ4go9hbmMeta } from "/app/pages/import/import-users.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as indexQuF0P9B2ZtMeta } from "/app/pages/membership-types/index.vue?macro=true";
import { default as _91id_93B6haXaf5WTMeta } from "/app/pages/newsletters/[id].vue?macro=true";
import { default as index695n8Itvb4Meta } from "/app/pages/newsletters/index.vue?macro=true";
import { default as index6cJTkh97P1Meta } from "/app/pages/options/index.vue?macro=true";
import { default as _91id_93m5hwe6XdulMeta } from "/app/pages/orders/[id].vue?macro=true";
import { default as indexG1Ehu1sqkUMeta } from "/app/pages/orders/index.vue?macro=true";
import { default as password_45reset39IAnjWvPiMeta } from "/app/pages/password-reset.vue?macro=true";
import { default as _91id_93VVAEZJEHZ4Meta } from "/app/pages/payments/[id].vue?macro=true";
import { default as index4DIviTw3fSMeta } from "/app/pages/payments/index.vue?macro=true";
import { default as _91id_93HEsRsqVRWKMeta } from "/app/pages/plan-variants/[id].vue?macro=true";
import { default as _91id_93TMdbsQPypXMeta } from "/app/pages/plans/[id].vue?macro=true";
import { default as indexQcXPkMpePVMeta } from "/app/pages/plans/index.vue?macro=true";
import { default as indexNwMsGxInsLMeta } from "/app/pages/prices/index.vue?macro=true";
import { default as _91id_93LhKZBSW6A9Meta } from "/app/pages/product-groups/[id].vue?macro=true";
import { default as indexrOQ407fBafMeta } from "/app/pages/product-groups/index.vue?macro=true";
import { default as _91id_93kVZKMJ3tbEMeta } from "/app/pages/products/[id].vue?macro=true";
import { default as index18IXo8oN8QMeta } from "/app/pages/products/index.vue?macro=true";
import { default as _91id_93sGnY4AWPvHMeta } from "/app/pages/promotion-groups/[id].vue?macro=true";
import { default as index6A2LS1kWS5Meta } from "/app/pages/promotion-groups/index.vue?macro=true";
import { default as _91id_93gpoZdQWNjQMeta } from "/app/pages/promotions/[id].vue?macro=true";
import { default as indexXPFPtZfQvqMeta } from "/app/pages/promotions/index.vue?macro=true";
import { default as index8cIgfiqb4eMeta } from "/app/pages/qs-settings/index.vue?macro=true";
import { default as indexVwa9x8jtFbMeta } from "/app/pages/recent-orders/index.vue?macro=true";
import { default as _91id_93q87PjBVG7xMeta } from "/app/pages/roles/[id].vue?macro=true";
import { default as indexd33eSnEIJHMeta } from "/app/pages/roles/index.vue?macro=true";
import { default as _91id_93IpGiesVr1EMeta } from "/app/pages/rooms/[id].vue?macro=true";
import { default as indexiBg22gAZ1YMeta } from "/app/pages/rooms/index.vue?macro=true";
import { default as backupdt1KHefvbbMeta } from "/app/pages/sales-dashboard/backup.vue?macro=true";
import { default as indexPqAj9udSNSMeta } from "/app/pages/sales-dashboard/index.vue?macro=true";
import { default as _91id_93iNgd5LFIm0Meta } from "/app/pages/sales-groups/[id].vue?macro=true";
import { default as indexvtj3OMzySFMeta } from "/app/pages/sales-groups/index.vue?macro=true";
import { default as _91id_93SpFsTy9uX8Meta } from "/app/pages/shipping-methods/[id].vue?macro=true";
import { default as indexAlu3MlUQ3BMeta } from "/app/pages/shipping-methods/index.vue?macro=true";
import { default as _91id_93KHR0g6NGqTMeta } from "/app/pages/shops/[id].vue?macro=true";
import { default as indexqybDxIRgfkMeta } from "/app/pages/shops/index.vue?macro=true";
import { default as indexnpDdkKY6ElMeta } from "/app/pages/skategorie/index.vue?macro=true";
import { default as _91id_93J6mRnThw4RMeta } from "/app/pages/support/[id].vue?macro=true";
import { default as indexrVEAE3qysDMeta } from "/app/pages/support/index.vue?macro=true";
import { default as _91id_93B7HImczCcIMeta } from "/app/pages/surveys/[id].vue?macro=true";
import { default as indexfpe18gV1u8Meta } from "/app/pages/surveys/index.vue?macro=true";
import { default as _91id_93MgPppxubXVMeta } from "/app/pages/tasks/[id].vue?macro=true";
import { default as indexG8Y0JnMIO5Meta } from "/app/pages/tasks/index.vue?macro=true";
import { default as _91id_938uf3QX5Qm6Meta } from "/app/pages/taxes/[id].vue?macro=true";
import { default as indexs8IcPG2ALIMeta } from "/app/pages/taxes/index.vue?macro=true";
import { default as _91id_93aS3f7O5iFmMeta } from "/app/pages/user-groups/[id].vue?macro=true";
import { default as indexfr804IlbtmMeta } from "/app/pages/user-groups/index.vue?macro=true";
import { default as _91id_93nDQ2NKWll2Meta } from "/app/pages/users/[id].vue?macro=true";
import { default as indexnq54o81oozMeta } from "/app/pages/users/index.vue?macro=true";
import { default as indexGHd4w6nnwxMeta } from "/app/pages/utilization-dashboard/index.vue?macro=true";
import { default as _91id_93BvkBHZsZNfMeta } from "/app/pages/vouchers/[id].vue?macro=true";
import { default as index2CYM0Dl3omMeta } from "/app/pages/vouchers/index.vue?macro=true";
export default [
  {
    name: "admin-groups-id",
    path: "/admin-groups/:id()",
    meta: _91id_934ZWuaOswF8Meta || {},
    component: () => import("/app/pages/admin-groups/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin-groups",
    path: "/admin-groups",
    meta: indexnqshLyEGcZMeta || {},
    component: () => import("/app/pages/admin-groups/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-password",
    path: "/admin-password",
    meta: admin_45passwordySuk729nHNMeta || {},
    component: () => import("/app/pages/admin-password.vue").then(m => m.default || m)
  },
  {
    name: "admins-id",
    path: "/admins/:id()",
    meta: _91id_93beSdQH3MBSMeta || {},
    component: () => import("/app/pages/admins/[id].vue").then(m => m.default || m)
  },
  {
    name: "admins",
    path: "/admins",
    meta: indexbfLlA1cqPPMeta || {},
    component: () => import("/app/pages/admins/index.vue").then(m => m.default || m)
  },
  {
    name: "article-groups-id",
    path: "/article-groups/:id()",
    meta: _91id_93c7tReH6grJMeta || {},
    component: () => import("/app/pages/article-groups/[id].vue").then(m => m.default || m)
  },
  {
    name: "article-groups",
    path: "/article-groups",
    meta: indexP1CnPDCqk1Meta || {},
    component: () => import("/app/pages/article-groups/index.vue").then(m => m.default || m)
  },
  {
    name: "articles-id",
    path: "/articles/:id()",
    meta: _91id_93GfbQespuG8Meta || {},
    component: () => import("/app/pages/articles/[id].vue").then(m => m.default || m)
  },
  {
    name: "articles",
    path: "/articles",
    meta: indexnFjuerN8cOMeta || {},
    component: () => import("/app/pages/articles/index.vue").then(m => m.default || m)
  },
  {
    name: "clients-id",
    path: "/clients/:id()",
    meta: _91id_93Z5L4MqZBatMeta || {},
    component: () => import("/app/pages/clients/[id].vue").then(m => m.default || m)
  },
  {
    name: "clients",
    path: "/clients",
    meta: indexwYSyEtRV2HMeta || {},
    component: () => import("/app/pages/clients/index.vue").then(m => m.default || m)
  },
  {
    name: "code-check",
    path: "/code-check",
    meta: code_45checkPO2a98MchtMeta || {},
    component: () => import("/app/pages/code-check.vue").then(m => m.default || m)
  },
  {
    name: "custom-dashboards-id",
    path: "/custom-dashboards/:id()",
    meta: _91id_93zJv1nbROrlMeta || {},
    component: () => import("/app/pages/custom-dashboards/[id].vue").then(m => m.default || m)
  },
  {
    name: "custom-dashboards-index_deprecated",
    path: "/custom-dashboards/index_deprecated",
    component: () => import("/app/pages/custom-dashboards/index_deprecated.vue").then(m => m.default || m)
  },
  {
    name: "custom-dashboards",
    path: "/custom-dashboards",
    meta: index8eQYOfyVjPMeta || {},
    component: () => import("/app/pages/custom-dashboards/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboards",
    path: "/dashboards",
    meta: index0BjuCgbIktMeta || {},
    component: () => import("/app/pages/dashboards/index.vue").then(m => m.default || m)
  },
  {
    name: "email-template-id",
    path: "/email-template/:id()",
    meta: _91id_93JEAC5F6UZ2Meta || {},
    component: () => import("/app/pages/email-template/[id].vue").then(m => m.default || m)
  },
  {
    name: "email-template",
    path: "/email-template",
    meta: index5tHTuHPE2tMeta || {},
    component: () => import("/app/pages/email-template/index.vue").then(m => m.default || m)
  },
  {
    name: "event-dates-orders",
    path: "/event-dates-orders",
    meta: index3QkSrmWestMeta || {},
    component: () => import("/app/pages/event-dates-orders/index.vue").then(m => m.default || m)
  },
  {
    name: "event-dates-id",
    path: "/event-dates/:id()",
    meta: _91id_9322uPchKg2tMeta || {},
    component: () => import("/app/pages/event-dates/[id].vue").then(m => m.default || m)
  },
  {
    name: "event-dates-edit",
    path: "/event-dates/edit",
    meta: edit9gCEm6hzdNMeta || {},
    component: () => import("/app/pages/event-dates/edit.vue").then(m => m.default || m)
  },
  {
    name: "event-dates",
    path: "/event-dates",
    meta: index3i2ckkM9YUMeta || {},
    component: () => import("/app/pages/event-dates/index.vue").then(m => m.default || m)
  },
  {
    name: "events-id",
    path: "/events/:id()",
    meta: _91id_932G5SX2LjFTMeta || {},
    component: () => import("/app/pages/events/[id].vue").then(m => m.default || m)
  },
  {
    name: "events",
    path: "/events",
    meta: indexWgIHVzns1AMeta || {},
    component: () => import("/app/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: "export-export-datev",
    path: "/export/export-datev",
    meta: export_45datevMVL7plpCStMeta || {},
    component: () => import("/app/pages/export/export-datev.vue").then(m => m.default || m)
  },
  {
    name: "filters-id",
    path: "/filters/:id()",
    meta: _91id_93IksUdDnzziMeta || {},
    component: () => import("/app/pages/filters/[id].vue").then(m => m.default || m)
  },
  {
    name: "filters",
    path: "/filters",
    meta: index3WCdzcyqjgMeta || {},
    component: () => import("/app/pages/filters/index.vue").then(m => m.default || m)
  },
  {
    name: "import-import-users",
    path: "/import/import-users",
    meta: import_45users1oJ4go9hbmMeta || {},
    component: () => import("/app/pages/import/import-users.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginhHM0vSTW5jMeta || {},
    component: () => import("/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "membership-types",
    path: "/membership-types",
    component: () => import("/app/pages/membership-types/index.vue").then(m => m.default || m)
  },
  {
    name: "newsletters-id",
    path: "/newsletters/:id()",
    meta: _91id_93B6haXaf5WTMeta || {},
    component: () => import("/app/pages/newsletters/[id].vue").then(m => m.default || m)
  },
  {
    name: "newsletters",
    path: "/newsletters",
    meta: index695n8Itvb4Meta || {},
    component: () => import("/app/pages/newsletters/index.vue").then(m => m.default || m)
  },
  {
    name: "options",
    path: "/options",
    meta: index6cJTkh97P1Meta || {},
    component: () => import("/app/pages/options/index.vue").then(m => m.default || m)
  },
  {
    name: "orders-id",
    path: "/orders/:id()",
    meta: _91id_93m5hwe6XdulMeta || {},
    component: () => import("/app/pages/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: "orders",
    path: "/orders",
    meta: indexG1Ehu1sqkUMeta || {},
    component: () => import("/app/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "password-reset",
    path: "/password-reset",
    meta: password_45reset39IAnjWvPiMeta || {},
    component: () => import("/app/pages/password-reset.vue").then(m => m.default || m)
  },
  {
    name: "payments-id",
    path: "/payments/:id()",
    meta: _91id_93VVAEZJEHZ4Meta || {},
    component: () => import("/app/pages/payments/[id].vue").then(m => m.default || m)
  },
  {
    name: "payments",
    path: "/payments",
    meta: index4DIviTw3fSMeta || {},
    component: () => import("/app/pages/payments/index.vue").then(m => m.default || m)
  },
  {
    name: "plan-variants-id",
    path: "/plan-variants/:id()",
    meta: _91id_93HEsRsqVRWKMeta || {},
    component: () => import("/app/pages/plan-variants/[id].vue").then(m => m.default || m)
  },
  {
    name: "plans-id",
    path: "/plans/:id()",
    meta: _91id_93TMdbsQPypXMeta || {},
    component: () => import("/app/pages/plans/[id].vue").then(m => m.default || m)
  },
  {
    name: "plans",
    path: "/plans",
    meta: indexQcXPkMpePVMeta || {},
    component: () => import("/app/pages/plans/index.vue").then(m => m.default || m)
  },
  {
    name: "prices",
    path: "/prices",
    meta: indexNwMsGxInsLMeta || {},
    component: () => import("/app/pages/prices/index.vue").then(m => m.default || m)
  },
  {
    name: "product-groups-id",
    path: "/product-groups/:id()",
    meta: _91id_93LhKZBSW6A9Meta || {},
    component: () => import("/app/pages/product-groups/[id].vue").then(m => m.default || m)
  },
  {
    name: "product-groups",
    path: "/product-groups",
    meta: indexrOQ407fBafMeta || {},
    component: () => import("/app/pages/product-groups/index.vue").then(m => m.default || m)
  },
  {
    name: "products-id",
    path: "/products/:id()",
    meta: _91id_93kVZKMJ3tbEMeta || {},
    component: () => import("/app/pages/products/[id].vue").then(m => m.default || m)
  },
  {
    name: "products",
    path: "/products",
    meta: index18IXo8oN8QMeta || {},
    component: () => import("/app/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: "promotion-groups-id",
    path: "/promotion-groups/:id()",
    meta: _91id_93sGnY4AWPvHMeta || {},
    component: () => import("/app/pages/promotion-groups/[id].vue").then(m => m.default || m)
  },
  {
    name: "promotion-groups",
    path: "/promotion-groups",
    meta: index6A2LS1kWS5Meta || {},
    component: () => import("/app/pages/promotion-groups/index.vue").then(m => m.default || m)
  },
  {
    name: "promotions-id",
    path: "/promotions/:id()",
    meta: _91id_93gpoZdQWNjQMeta || {},
    component: () => import("/app/pages/promotions/[id].vue").then(m => m.default || m)
  },
  {
    name: "promotions",
    path: "/promotions",
    meta: indexXPFPtZfQvqMeta || {},
    component: () => import("/app/pages/promotions/index.vue").then(m => m.default || m)
  },
  {
    name: "qs-settings",
    path: "/qs-settings",
    meta: index8cIgfiqb4eMeta || {},
    component: () => import("/app/pages/qs-settings/index.vue").then(m => m.default || m)
  },
  {
    name: "recent-orders",
    path: "/recent-orders",
    meta: indexVwa9x8jtFbMeta || {},
    component: () => import("/app/pages/recent-orders/index.vue").then(m => m.default || m)
  },
  {
    name: "roles-id",
    path: "/roles/:id()",
    meta: _91id_93q87PjBVG7xMeta || {},
    component: () => import("/app/pages/roles/[id].vue").then(m => m.default || m)
  },
  {
    name: "roles",
    path: "/roles",
    meta: indexd33eSnEIJHMeta || {},
    component: () => import("/app/pages/roles/index.vue").then(m => m.default || m)
  },
  {
    name: "rooms-id",
    path: "/rooms/:id()",
    meta: _91id_93IpGiesVr1EMeta || {},
    component: () => import("/app/pages/rooms/[id].vue").then(m => m.default || m)
  },
  {
    name: "rooms",
    path: "/rooms",
    meta: indexiBg22gAZ1YMeta || {},
    component: () => import("/app/pages/rooms/index.vue").then(m => m.default || m)
  },
  {
    name: "sales-dashboard-backup",
    path: "/sales-dashboard/backup",
    meta: backupdt1KHefvbbMeta || {},
    component: () => import("/app/pages/sales-dashboard/backup.vue").then(m => m.default || m)
  },
  {
    name: "sales-dashboard",
    path: "/sales-dashboard",
    meta: indexPqAj9udSNSMeta || {},
    component: () => import("/app/pages/sales-dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "sales-groups-id",
    path: "/sales-groups/:id()",
    meta: _91id_93iNgd5LFIm0Meta || {},
    component: () => import("/app/pages/sales-groups/[id].vue").then(m => m.default || m)
  },
  {
    name: "sales-groups",
    path: "/sales-groups",
    meta: indexvtj3OMzySFMeta || {},
    component: () => import("/app/pages/sales-groups/index.vue").then(m => m.default || m)
  },
  {
    name: "shipping-methods-id",
    path: "/shipping-methods/:id()",
    meta: _91id_93SpFsTy9uX8Meta || {},
    component: () => import("/app/pages/shipping-methods/[id].vue").then(m => m.default || m)
  },
  {
    name: "shipping-methods",
    path: "/shipping-methods",
    meta: indexAlu3MlUQ3BMeta || {},
    component: () => import("/app/pages/shipping-methods/index.vue").then(m => m.default || m)
  },
  {
    name: "shops-id",
    path: "/shops/:id()",
    meta: _91id_93KHR0g6NGqTMeta || {},
    component: () => import("/app/pages/shops/[id].vue").then(m => m.default || m)
  },
  {
    name: "shops",
    path: "/shops",
    meta: indexqybDxIRgfkMeta || {},
    component: () => import("/app/pages/shops/index.vue").then(m => m.default || m)
  },
  {
    name: "skategorie",
    path: "/skategorie",
    meta: indexnpDdkKY6ElMeta || {},
    component: () => import("/app/pages/skategorie/index.vue").then(m => m.default || m)
  },
  {
    name: "support-id",
    path: "/support/:id()",
    meta: _91id_93J6mRnThw4RMeta || {},
    component: () => import("/app/pages/support/[id].vue").then(m => m.default || m)
  },
  {
    name: "support",
    path: "/support",
    meta: indexrVEAE3qysDMeta || {},
    component: () => import("/app/pages/support/index.vue").then(m => m.default || m)
  },
  {
    name: "surveys-id",
    path: "/surveys/:id()",
    meta: _91id_93B7HImczCcIMeta || {},
    component: () => import("/app/pages/surveys/[id].vue").then(m => m.default || m)
  },
  {
    name: "surveys",
    path: "/surveys",
    meta: indexfpe18gV1u8Meta || {},
    component: () => import("/app/pages/surveys/index.vue").then(m => m.default || m)
  },
  {
    name: "tasks-id",
    path: "/tasks/:id()",
    meta: _91id_93MgPppxubXVMeta || {},
    component: () => import("/app/pages/tasks/[id].vue").then(m => m.default || m)
  },
  {
    name: "tasks",
    path: "/tasks",
    meta: indexG8Y0JnMIO5Meta || {},
    component: () => import("/app/pages/tasks/index.vue").then(m => m.default || m)
  },
  {
    name: "taxes-id",
    path: "/taxes/:id()",
    meta: _91id_938uf3QX5Qm6Meta || {},
    component: () => import("/app/pages/taxes/[id].vue").then(m => m.default || m)
  },
  {
    name: "taxes",
    path: "/taxes",
    meta: indexs8IcPG2ALIMeta || {},
    component: () => import("/app/pages/taxes/index.vue").then(m => m.default || m)
  },
  {
    name: "user-groups-id",
    path: "/user-groups/:id()",
    meta: _91id_93aS3f7O5iFmMeta || {},
    component: () => import("/app/pages/user-groups/[id].vue").then(m => m.default || m)
  },
  {
    name: "user-groups",
    path: "/user-groups",
    meta: indexfr804IlbtmMeta || {},
    component: () => import("/app/pages/user-groups/index.vue").then(m => m.default || m)
  },
  {
    name: "users-id",
    path: "/users/:id()",
    meta: _91id_93nDQ2NKWll2Meta || {},
    component: () => import("/app/pages/users/[id].vue").then(m => m.default || m)
  },
  {
    name: "users",
    path: "/users",
    meta: indexnq54o81oozMeta || {},
    component: () => import("/app/pages/users/index.vue").then(m => m.default || m)
  },
  {
    name: "utilization-dashboard",
    path: "/utilization-dashboard",
    meta: indexGHd4w6nnwxMeta || {},
    component: () => import("/app/pages/utilization-dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "vouchers-id",
    path: "/vouchers/:id()",
    meta: _91id_93BvkBHZsZNfMeta || {},
    component: () => import("/app/pages/vouchers/[id].vue").then(m => m.default || m)
  },
  {
    name: "vouchers",
    path: "/vouchers",
    meta: index2CYM0Dl3omMeta || {},
    component: () => import("/app/pages/vouchers/index.vue").then(m => m.default || m)
  }
]