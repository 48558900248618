import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_2LpVRIemQY from "/app/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_YeYU6tgo5H from "/app/node_modules/@davestewart/nuxt-scrollbar/dist/runtime/plugin.mjs";
import plugin_eskviOYyLt from "/app/node_modules/nuxt-vue3-google-signin/dist/runtime/plugin.mjs";
import plugin_AOuQ1DYzjk from "/app/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import plugin_client_OVoKJro5pc from "/app/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_vue3_A0OWXRrUgq from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import bootstrap_tF8BwObPnp from "/app/plugins/default/bootstrap.js";
import editor_QXroW5Da3A from "/app/plugins/default/editor.js";
import filterTable_r0il7yMfM8 from "/app/plugins/default/filterTable.js";
import stripHtmlPlugin_Zcgb3bNN7L from "/app/plugins/stripHtmlPlugin.js";
import draggable_EKFVpjGqMx from "/app/plugins/draggable.js";
import highcharts_client_VdyRlU1pE6 from "/app/plugins/highcharts.client.js";
import copy_YoTfzLNSzv from "/app/plugins/copy.js";
import error_Z9R5b5Ynf8 from "/app/plugins/error.js";
import filter_kq7ptQixcW from "/app/plugins/filter.js";
import repository_GQYe7Z65pL from "/app/plugins/repository.js";
import socket_io_zZ5syzQeX1 from "/app/plugins/socket.io.js";
import toast_Zl2rZYKMaV from "/app/plugins/toast.js";
import tools_BFszRjRKSa from "/app/plugins/tools.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  view_transitions_client_2LpVRIemQY,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_YeYU6tgo5H,
  plugin_eskviOYyLt,
  plugin_AOuQ1DYzjk,
  plugin_client_OVoKJro5pc,
  plugin_vue3_A0OWXRrUgq,
  bootstrap_tF8BwObPnp,
  editor_QXroW5Da3A,
  filterTable_r0il7yMfM8,
  stripHtmlPlugin_Zcgb3bNN7L,
  draggable_EKFVpjGqMx,
  highcharts_client_VdyRlU1pE6,
  copy_YoTfzLNSzv,
  error_Z9R5b5Ynf8,
  filter_kq7ptQixcW,
  repository_GQYe7Z65pL,
  socket_io_zZ5syzQeX1,
  toast_Zl2rZYKMaV,
  tools_BFszRjRKSa
]