import Swal from "sweetalert2";

export default defineNuxtPlugin(async (nuxtApp) => {
  const Toast = Swal.mixin({
    buttonsStyling: false,
    showCloseButton: true,
    confirmButtonText: "<div class='icon d-inline-flex bg-white align-items-center justify-content-center' style='width:24px;height:24px;'><svg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' role='img' class='align-items-center justify-content-center iconify iconify--material-symbols-light' style='font-size:1rem' width='1em' height='1em' viewBox='0 0 24 24'><path fill='currentColor' d='m9.55 17.308l-4.97-4.97l.714-.713l4.256 4.256l9.156-9.156l.713.714z'></path></svg></div> Okay",
    cancelButtonText: "<div class='icon d-inline-flex bg-white align-items-center justify-content-center' style='width:24px;height:24px;'><svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 24 24'><path fill='currentColor' d='m8.401 16.333l-.734-.727L11.266 12L7.667 8.42l.734-.728L12 11.29l3.574-3.597l.734.727L12.709 12l3.599 3.606l-.734.727L12 12.737z'/></svg></div> Abbrechen",
    reverseButtons: true,
    customClass: {
      confirmButton: "btn btn-primary max-width-200 btn-icon btn-icon-custom",
      cancelButton: "btn btn-outline-secondary max-width-200 btn-icon btn-icon-custom",
    },
  });

  nuxtApp.toast = {
    async showError(title, info) {
      await Toast.fire({
        title,
        html: info,
      });
    },
    async showConfirmation(title, info) {
      return await Toast.fire({
        title,
        html: info,
        confirmButtonText: "<div class='icon d-inline-flex bg-white align-items-center justify-content-center' style='width:24px;height:24px;'><svg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' role='img' class='align-items-center justify-content-center iconify iconify--material-symbols-light' style='font-size:1rem' width='1em' height='1em' viewBox='0 0 24 24'><path fill='currentColor' d='m9.55 17.308l-4.97-4.97l.714-.713l4.256 4.256l9.156-9.156l.713.714z'></path></svg></div> Ja",
        cancelButtonText: "<div class='icon d-inline-flex bg-white align-items-center justify-content-center' style='width:24px;height:24px;'><svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 24 24'><path fill='currentColor' d='m8.401 16.333l-.734-.727L11.266 12L7.667 8.42l.734-.728L12 11.29l3.574-3.597l.734.727L12.709 12l3.599 3.606l-.734.727L12 12.737z'/></svg></div> Nein",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary max-width-200 btn-icon btn-icon-custom",
          cancelButton: "btn btn-outline-secondary max-width-200 btn-icon btn-icon-custom",
        },
      });
    },
    showSuccess(info) {
      Toast.fire({
        icon: "success",
        html: info,
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
    },
  };
});
