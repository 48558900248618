import { useToken } from "~/composables/admin";

export default () => ({
  apiURL: `${useRuntimeConfig().public.apiURL}/admins`,
  apiURLGroups: `${useRuntimeConfig().public.apiURL}/admin-groups`,
  async getAdmin(id) {
    return await $fetch(`${this.apiURL}/${id}`, { headers: { Authorization: useToken().value } });
  },
  async getAdminByToken(token) {
    return await $fetch(`${this.apiURL}/get-admin/${token}`);
  },
  async saveAdmin(admin) {
    return await $fetch(this.apiURL, {
      method: admin.id ? "PUT" : "POST",
      headers: { Authorization: useToken().value },
      body: { ...admin },
    });
  },
  async resetPassword(token, password, passwordConfirm) {
    return await $fetch(`${this.apiURL}/reset-password`, {
      method: "POST",
      body: { token, password, password_confirmation: passwordConfirm },
    });
  },
  async addFilter(id, filterId) {
    return await $fetch(`${this.apiURL}/add-filter`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { id, filter_id: filterId },
    });
  },
  async updateAdminPermissions(adminId, clientId, permissions) {
    return await $fetch(`${this.apiURL}/update-permissions`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: {
        adminId,
        clientId,
        permissions: { ...permissions },
      },
    });
  },
  async deleteAdmin(admin) {
    return await $fetch(this.apiURL, {
      method: "DELETE",
      headers: { Authorization: useToken().value },
      body: { id: admin.value.id },
    });
  },
  async getAdminGroups() {
    return await $fetch(this.apiURLGroups, { headers: { Authorization: useToken().value } });
  },
  async getAdminGroup(id) {
    return await $fetch(`${this.apiURLGroups}/${id}`, { headers: { Authorization: useToken().value } });
  },
  async getAdminsByRole(id) {
    return await $fetch(`${this.apiURLGroups}/get-admins/${id}`, { headers: { Authorization: useToken().value } });
  },
  async getAllAdmins() {
    return await $fetch(`${this.apiURL}/all`, { headers: { Authorization: useToken().value } });
  },
  async saveAdminGroup(group) {
    return await $fetch(this.apiURLGroups, {
      method: group.id ? "PUT" : "POST",
      headers: { Authorization: useToken().value },
      body: { ...group },
    });
  },
  async deleteAdminGroup(group) {
    return await $fetch(this.apiURLGroups, {
      method: "DELETE",
      headers: { Authorization: useToken().value },
      body: { id: group.id },
    });
  },
  async uploadImage(id, image) {
    const formData = new FormData();
    formData.append("id", id);
    formData.append("image", image);
    return await $fetch(`${this.apiURL}/upload-image`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: formData,
    });
  },
  async removeImage(id) {
    return await $fetch(`${this.apiURL}/remove-image`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { id },
    });
  },
});
