export default () => ({
  apiURL: `${useRuntimeConfig().public.apiURL}/filters`,
  async getFilter(id) {
    return await $fetch(`${this.apiURL}/${id}`, { headers: { Authorization: useToken().value } });
  },
  async getDatabaseStructure() {
    return await $fetch(`${this.apiURL}/database-structure`, {
      method: "GET",
      headers: { Authorization: useToken().value },
    });
  },
  async getFilterGroups(type) {
    return await $fetch(`${this.apiURL}/filter-groups?type=${type}`, {
      method: "GET",
      headers: { Authorization: useToken().value },
    });
  },
  async createFilter(filter, select) {
    return await $fetch(this.apiURL, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { ...filter, select },
    });
  },
  async deleteFilter(filter, force = false) {
    return await $fetch(this.apiURL, {
      method: "DELETE",
      headers: { Authorization: useToken().value },
      body: { id: filter.id, force },
    });
  },
  async queryAi(clientId, prompt) {
    return await $fetch(`${this.apiURL}/query-ai`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { clientId, prompt },
    });
  },
  async saveFilter(filter) {
    return await $fetch(`${this.apiURL}/save-filter`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { ...filter },
    });
  },
  async saveFilterGroup(filterGroup) {
    return await $fetch(`${this.apiURL}/save-filter-group`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { ...filterGroup },
    });
  },
  async saveFilterColumns(filter, columns) {
    return await $fetch(`${this.apiURL}/save-filter-columns`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { filter_id: filter.id, columns },
    });
  },
  async saveFilterQuery(filter, query, whipeCharts = false) {
    return await $fetch(`${this.apiURL}/save-filter-query`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { filter_id: filter.id, query, whipeCharts },
    });
  },
  async saveFilterOption(filterOption) {
    return await $fetch(`${this.apiURL}/save-filter-option`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { ...filterOption },
    });
  },
  async deleteFilterOption(filterOption) {
    return await $fetch(`${this.apiURL}/delete-filter-option`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { id: filterOption.id },
    });
  },
  async saveFilterOptionSort(filterId, sort) {
    return await $fetch(`${this.apiURL}/save-filter-option-sort`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: { filter_id: filterId, sort },
    });
  },

  async getChartData(filterId) {
    return await $fetch(`${this.apiURL}/${filterId}/query`, {
      method: "GET",
      headers: { Authorization: useToken().value },
    });
  },

  async getFilterValues(type, value, column) {
    return await $fetch(`${this.apiURL}/get-filter-values`, {
      method: "GET",
      headers: { Authorization: useToken().value },
      params: { type, value, column },
    });
  },
  async queryColumns(query) {
    return await $fetch(`${this.apiURL}/query-columns`, {
      method: "GET",
      headers: { Authorization: useToken().value },
      params: { query },
    });
  },
  async saveFilterExportSettings(filterId, exportName, pageLength, exportPdfFormat, exportMessageTop, exportMessageBottom) {
    return await $fetch(`${this.apiURL}/save-filter-settings`, {
      method: "POST",
      headers: { Authorization: useToken().value },
      body: {
        filter_id: filterId,
        export_name: exportName,
        page_length: pageLength,
        export_pdf_format: exportPdfFormat,
        export_message_top: exportMessageTop,
        export_message_bottom: exportMessageBottom,
      },
    });
  },
});
