<template>
  <button v-if="allowed" :type="type" :class="buttonClasses" :disabled="disabled">
    <div v-if="icon && htmlType !== 'action'" class="icon d-inline-flex bg-white align-items-center justify-content-center" :style="{ width: `24px`, height: `24px` }">
      <Icon :icon="icon" class="align-items-center justify-content-center" :style="{ fontSize: `1rem` }" />
    </div>
    <span v-else-if="icon && htmlType === 'action'" class="icon">
      <Icon :icon="icon" />
    </span>

    <span :class="{ 'd-none': !slotHasContent || slotHasContent === '<!--[--><!--]-->' }" class="text">
      <div ref="content" class="text-firstLine">
        <slot />
      </div>
      <div class="text-secondLine">
        <slot />
      </div>
    </span>
  </button>
</template>

<script setup>
import { useAuthStore } from "~/stores/AuthStore";

const props = defineProps({
  htmlType: {
    type: String,
    default: "primary",
  },
  icon: {
    type: String,
  },
  iconPosition: {
    type: String,
    default: "left",
    validator: value => [ "left", "right" ].includes(value),
  },
  submit: {
    type: Boolean,
    default: false,
  },
  disabled: {},
  ignorePermission: {
    default: false,
  },
  size: {
    type: String,
    default: "md",
    validator: value => [ "xs", "sm", "md", "lg" ].includes(value),
  },
});

const content = ref(null);
const slotHasContent = computed(() => {
  return content.value?.innerHTML;
});
const type = ref("button");
if (props.submit) type.value = "submit";
const setIconClass = ref("");
if (props.icon) setIconClass.value = "btn-icon";

// Berechtigung überprüfen
const route = useRoute();
const authStore = useAuthStore();
const allowed = ref(true);
const buttonClasses = computed(() => {
  return [
    "btn",
    `btn-${props.htmlType}`,
    `btn-${props.size}`,
    `${setIconClass.value}`,
  ];
});
if (route.meta.permission && !props.ignorePermission && !authStore.hasPermission([ route.meta.permission ])) allowed.value = false;
</script>

<style scoped>
.btn-custom-xs {
  padding: 0.1rem 0.3rem;
  font-size: 0.75rem;
  line-height: 1.2;
}
</style>
